import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { mutate } from "swr";
import { useAuth0 } from "@auth0/auth0-react";
import { useHedgingContext } from "@/context/betaContext";
import clsx from "clsx";

import isEmpty from "lodash.isempty";
import map from "lodash.map";

import { formatDecimalNumber } from "../lib/text";
import {
  apiGetWithoutTokenRequest,
  apiPostRequest,
  apiGetRequest,
} from "@/lib/api";
import { apiKeys } from "@/lib/regex";
import {
  modifyHedgePercent,
  negativeBetaError,
  updatedPortfolioData,
} from "@/lib/formulas";
import {
  LineTitle,
  Tooltip,
  HtmlFragment as HF,
  Icon,
  RangeSlider as RS,
  Rebalancing,
} from "./*";
import { filterData } from "./builder-tabs";
import DisableState from "./disable-state";
import config from "@/config";

const BASE_CLASS = "set-hedge";

function SetHedge(props) {
  const { className, hedge, results, investment } = props;
  const [rangeVal, setRangeVal] = useState(50);
  const [indexData, setIndexData] = useState([]);
  const [selectedRelatedOption, setSelectedRelatedOption] = useState(null);
  const [indexCode, setIndexCode] = useState("");
  const [multiCode, setMultiCode] = useState("");
  const [currentMulti, setCurrentMulti] = useState(-1);
  const [betaIndex, setBetaIndex] = useState(0);
  const [selectedId, setSelectedId] = useState("SH");
  const [betaId, setBetaId] = useState(-0.9764);
  const [openModal, setModal] = useState(false);
  const [indexDrop, setIndexDrop] = useState("S&P 500");
  const [multiDrop, setMultiDrop] = useState("-1x");
  const [currRateShow, setCurrRateShow] = useState(true);
  const [checkBeta, setCheckBeta] = useState(false);
  const [udateTicker, setUpdateTicker] = useState(true);
  const [editHoldingModal, setEditHoldingModal] = useState(false);

  const {
    isSelectIndex,
    isSelectMultiple,
    handleSelectIndex,
    handleSelectMultiple,
    isTotalPortfolio,
    handleSelectedIndexId,
    handleSelectedTickerBeta,
    getHedgeData,
    isNewRow,
    handleGetTickerBeta,
    getTickerBeta,
    modifyHedge,
    handleLoaderShow,
    handleInvestmentLoader,
    isLoginTableData,
    editHolding,
    rebalanceNew,
    updateModifyHedge,
    handleGetIndexData,
    handleIsAlert,
    updateDropdown,
    handleHandleWarning,
    isSelectedBeta,
    handleTickerCheck,
    tableData,
    handleDefaultUpdateTicker,
    defaultUpdateTicker,
    handleBlankValues,
    handleHedgeModal,
    handleShowModal,
    handleModifyHedge,
    handleModifyHedgeNew,
    handleTableData,
    handleRecall,
    betaValue,
    rangeData,
    editTableData,
    isSelectPortfolio,
    hedgeTotal,
    handleChangeEditLabel,
    handleIsHoldings,
    handleEditHolding,
    handleSaveHoldings,
    getIndexData,
    isDropChange,
    resetCode,
    changeModifyDropdown,
    handleSelectedIndex,
    selectedIndex,
    handleChangeMultiplier,
    graphData,
  } = useHedgingContext();

  const { isAuthenticated, user } = useAuth0();

  const originalCurrentUI =
    (!isEmpty(getHedgeData?.hedgeholding) && !isNewRow && !modifyHedge) ||
    (!isEmpty(isLoginTableData) && !isNewRow && !modifyHedge);

  const allIndexData = async () => {
    // Fetch data from an external API
    const res = await fetch(`${config?.baseUrl}/api/gethedgeticker`);
    const data = await res.json();
    setIndexData(data);
    return data;
  };

  useEffect(() => {
    allIndexData();
  }, []);

  const dataCheck = filterData(tableData, isSelectedBeta);

  const checkBlankTicker = tableData?.find(
    (cv) => !cv.value && !cv.weight && !!cv.ticker
  )?.ticker;

  const handleModal = () => {
    if (dataCheck.length === 0 && !checkBlankTicker) {
      setModal((prev) => !prev);
    } else {
      handleTickerCheck(true);
    }
    handleBlankValues(true);
  };

  const handleEditModal = () => {
    setEditHoldingModal((prev) => !prev);
    handleHedgeModal(false);
    // handleModal();
  };

  const selectedTickerId = changeModifyDropdown
    ? selectedId
    : getHedgeData?.hedgeholding?.HEDGE_TICKER;

  const selectedIndexId = indexData?.find(
    (val) => val.HEDGE_TICKER === selectedTickerId
  );

  const editSelectedIndexId = indexData?.find(
    (val) => val.HEDGE_TICKER === getHedgeData?.hedgeholding?.HEDGE_TICKER
  );

  handleSelectedIndexId(
    editHolding || rebalanceNew || updateModifyHedge
      ? editSelectedIndexId?.ID
      : (isNewRow && updateDropdown) || updateDropdown
      ? selectedIndexId?.ID
      : undefined
  );

  // Extract unique INDEX_NAME values and format them
  const indexNames = indexData.map((indexName) => ({
    INDEX_NAME: indexName.INDEX_NAME,
    MULTIPLIER: indexName.MULTIPLIER,
    HEDGE_TICKER: indexName.HEDGE_TICKER,
    INDEX_TICKER: indexName.INDEX_TICKER,
  }));

  // Function to filter unique objects based on INDEX_NAME
  function filterUniqueObjects(arr) {
    const seen = new Set();
    return arr.filter((obj) => {
      const indexName = obj.INDEX_NAME;
      if (seen.has(indexName)) {
        return false;
      }
      seen.add(indexName);
      return true;
    });
  }

  // Filtering unique objects based on INDEX_NAME
  let uniqueIndexNames = filterUniqueObjects(indexNames);

  // Filter related values based on selected INDEX_NAME
  const relatedOptions = selectedIndex
    ? indexData
        .filter((item) => item.INDEX_NAME === selectedIndex)
        .map((item) => ({
          INDEX_NAME: item.INDEX_NAME,
          MULTIPLIER: item.MULTIPLIER,
          HEDGE_TICKER: item.HEDGE_TICKER,
          INDEX_TICKER: item.INDEX_TICKER,
          ISMULTIPLIER: true,
        }))
    : [];

  const handleIndexChange = (name, multiplier, code) => {
    handleSelectedIndex(name);
    setSelectedRelatedOption("");
    setIndexCode(code);
  };

  const handleRelatedChange = (name, multiplier, code) => {
    setSelectedRelatedOption(name);
    setMultiCode(multiplier);
  };

  // dropdown select value
  const getSelectedTicker = async (selectedValue, MULTIPLIER) => {
    handleInvestmentLoader(true);
    const isSelectedValue = selectedValue;
    // resetCode || isNewRow
    //   ? selectedValue
    //   : getHedgeData?.hedgeholding?.INDEX_NAME;

    try {
      // Find the object in indexData that matches the selected INDEX_NAME and MULTIPLIER
      const selectedObject = indexData.find(
        (item) =>
          item.INDEX_NAME === isSelectedValue && item.MULTIPLIER === MULTIPLIER
      );

      if (selectedObject) {
        setSelectedId(selectedObject.HEDGE_TICKER);

        const tickerList = await mutate(apiKeys["hedgeTickerListKey"], () =>
          apiGetWithoutTokenRequest(
            `api/getindexticker?ticker=${selectedObject.HEDGE_TICKER}`
          )
        );
        if (tickerList.beta_afty === 0) {
          handleHandleWarning(true);
          handleIsAlert({
            open: true,
            message: "This fund is currently unavailable",
            error: true,
          });
        } else {
          handleHandleWarning(false);
        }

        const SP500 = new Set(["SH", "SDS", "SPXU"]);
        const SPMIDCAP = new Set(["MYY", "MZZ", "SMDD"]);
        const DJIA = new Set(["DOG", "DXD", "SDOW"]);
        const NASDAQ = new Set(["PSQ", "QID", "SQQQ"]);
        const RUSSELL = new Set(["RWM", "TWM", "SRTY"]);
        const MSCI = new Set(["EFZ", "EFU"]);
        const MSCIEMERGING = new Set(["EUM", "EEV", "EDZ"]);

        const temp = SP500.has(tickerList?.ticker)
          ? tickerList?.beta_gspc_indx
          : SPMIDCAP.has(tickerList?.ticker)
          ? tickerList?.beta_mid_indx
          : DJIA.has(tickerList?.ticker)
          ? tickerList?.beta_dja_indx
          : NASDAQ.has(tickerList?.ticker)
          ? tickerList?.beta_ndx_indx
          : RUSSELL.has(tickerList?.ticker)
          ? tickerList?.beta_iwm
          : MSCI.has(tickerList?.ticker)
          ? tickerList?.beta_efa
          : MSCIEMERGING.has(tickerList?.ticker)
          ? tickerList?.beta_eem
          : "";
        handleGetTickerBeta(temp);
        handleGetIndexData(tickerList);
        handleSelectedTickerBeta(temp);
      } else {
        setSelectedId("");
      }
    } catch (err) {
      setError("Failed to fetch ticker data");
      console.error("Error fetching ticker data:", err);
    } finally {
      handleInvestmentLoader(false);
    }
  };

  // This is for if not logged in
  useEffect(() => {
    if (!isAuthenticated && !modifyHedge && !updateDropdown && udateTicker) {
      const getCurrRate = async () => {
        try {
          const result = await apiGetWithoutTokenRequest(
            `api/getticker?ticker=SH`
          );

          if (result?.beta_gspc_indx) {
            handleGetTickerBeta(result.beta_gspc_indx);
          }
        } catch (error) {
          console.error("Error fetching ticker data:", error);
        }
      };
      setCurrRateShow(!!getCurrRate ? false : true);

      mutate(apiKeys["hedgeTickerListKey"], getCurrRate);
      setUpdateTicker(false);
    }
  }, [
    handleGetTickerBeta,
    isAuthenticated,
    modifyHedge,
    udateTicker,
    updateDropdown,
  ]);

  const CurrRate = useCallback(async () => {
    const getCurrRate = await mutate(apiKeys["hedgeTickerListKey"], () =>
      apiGetWithoutTokenRequest(`api/getticker?ticker=SH`)
    );

    handleGetTickerBeta(getCurrRate?.beta_gspc_indx);
    setCurrRateShow(!!getCurrRate ? false : true);
  }, [handleGetTickerBeta]);

  useEffect(() => {
    if (
      (isNewRow && !updateDropdown && defaultUpdateTicker) ||
      (isAuthenticated &&
        defaultUpdateTicker &&
        !isNewRow &&
        !updateDropdown &&
        !modifyHedge &&
        !editHolding)
    ) {
      CurrRate();
      handleDefaultUpdateTicker(false);
    }
  }, [
    CurrRate,
    defaultUpdateTicker,
    editHolding,
    handleDefaultUpdateTicker,
    isAuthenticated,
    isNewRow,
    modifyHedge,
    updateDropdown,
  ]);

  const totalHedgeExpo = betaValue * (rangeData / 100);

  const updatedData = updatedPortfolioData(getHedgeData?.holdings);

  const handleHedgeModify = async () => {
    handleModifyHedge(false);
    handleHedgeModal(false);
    handleShowModal(true);
    handleModifyHedgeNew(true);
    handleTableData(getHedgeData?.holdings);
    handleRecall(true);
    handleEditModal();
  };

  const handleModifyPortfolio = async () => {
    handleLoaderShow(true);
    handleModifyHedge(false);
    setModal(false);

    const totalUnhedgeExpo = betaValue - totalHedgeExpo;

    // Filter and sanitize holdings data
    const holdings = updatedData
      .filter((val) => !!val?.TICKER && typeof val?.TICKER === "string")
      .map((val) => ({
        TICKER: val?.TICKER,
        TICKER_NAME: val?.TICKER_NAME,
        PRICE: val?.CURRENT_PRICE,
        WEIGHT: val?.WEIGHT,
        UNITS: val?.UNITS,
        VALUE: val?.VALUE,
      }));

    if (holdings.length === 0) {
      console.error("Error: Holdings data is invalid after sanitization.");
      handleLoaderShow(false);
      handleIsAlert({
        open: true,
        message: "No valid holdings found after processing.",
        error: true,
      });
      return;
    }

    // Build request data
    const hedgeHolding = {
      OKTA_ID: user?.sub,
      PORTFOLIO_ID: isSelectPortfolio,
    };

    const REQUIRED_INVESTMENT =
      rebalanceNew || editHolding
        ? totalHedgeExpo /
          Math.abs(getHedgeData?.hedgeholding?.CURRENT_INDEX_BETA)
        : Number((totalHedgeExpo / Math.abs(getTickerBeta)).toFixed(5));

    let marketCloseValue;

    if (!updateDropdown && updateModifyHedge) {
      marketCloseValue = getHedgeData?.hedgeholding?.CURRENT_INDEX_RATE;
    } else {
      marketCloseValue = getIndexData?.market_close_value;
    }

    const builderData = {
      holdings,
      hedgeholding: hedgeHolding,
    };

    try {
      // Save holdings data
      const { data: addNewHolding } = await mutate(
        apiKeys["singleHolding"](user?.sub, isSelectPortfolio),
        () => apiPostRequest(`api/saveHoldings`, JSON.stringify(builderData)),
        { revalidate: true }
      );

      // Update hedgeholding data
      const hedgeholding = {
        OKTA_ID: user?.sub,
        PORTFOLIO_ID: isSelectPortfolio,
        HEDGE_TICKER_ID: selectedIndexId.ID || 1,
        HEDGE_PERCENT: rangeData,
        TOTAL_BETA_EXPOSURE: betaValue || 0,
        HEDGED_EXPOSURE: totalHedgeExpo || 0,
        UNHEDGED_EXPOSURE: totalUnhedgeExpo || 0,
        REQUIRED_INVESTMENT: REQUIRED_INVESTMENT || 0,
        HEDGED_TOTAL: hedgeTotal || 0,
        UNHEDGED_TOTAL: totalUnhedgeExpo || 0,
        HEDGED_UNITS: Number(REQUIRED_INVESTMENT / marketCloseValue),
      };

      await mutate(
        apiKeys["singleHolding"](user?.sub, isSelectPortfolio),
        () =>
          apiPostRequest(
            `api/hedgeholdings`,
            JSON.stringify({
              hedgeholding,
            })
          ),
        { revalidate: true }
      );

      // Success handling
      if (addNewHolding && !addNewHolding.error) {
        await mutate(
          apiKeys["singleHolding"](user?.sub, isSelectPortfolio),
          () =>
            apiGetRequest(
              `api/getholdings?oktaid=${user?.sub}&portfolioid=${isSelectPortfolio}`
            ),
          { revalidate: true }
        );

        await mutate(apiKeys["graphData"](user?.sub, isSelectPortfolio), () =>
          apiGetRequest(
            `/api/getperformancemonitor?oktaid=${user?.sub}&portfolioid=${isSelectPortfolio}`
          )
        );

        handleLoaderShow(false);
        handleIsAlert({
          open: true,
          message: "Your holdings have been updated.",
          error: false,
        });
        handleChangeEditLabel(false);
        handleIsHoldings(false);
        handleEditHolding(false);
        setEditHoldingModal(false);
        handleSaveHoldings(false);
        handleChangeMultiplier(false);
        localStorage.removeItem("builderData");
        localStorage.removeItem("percentage");
      } else {
        throw new Error("Failed to save holdings.");
      }
    } catch (error) {
      console.error("Error updating portfolio: ", error);
      handleLoaderShow(false);
      handleIsAlert({
        open: true,
        message: "Failed to update portfolio. Please try again later.",
        error: true,
      });
    }
  };

  return (
    <div className={`check ${clsx(BASE_CLASS, className)}`}>
      <div>
        {hedge?.title && !originalCurrentUI ? (
          <LineTitle text={hedge?.title} container={true} />
        ) : null}
        {!isEmpty(hedge?.options) ? (
          <Options
            hedge={hedge}
            rangeVal={rangeVal}
            setRangeVal={setRangeVal}
            handleSelectIndex={handleSelectIndex}
            handleSelectMultiple={handleSelectMultiple}
            uniqueIndexNames={uniqueIndexNames}
            relatedOptions={relatedOptions}
            selectedRelatedOption={selectedRelatedOption}
            handleIndexChange={handleIndexChange}
            handleRelatedChange={handleRelatedChange}
            indexData={indexData}
            currentMulti={currentMulti}
            setCurrentMulti={setCurrentMulti}
            betaIndex={betaIndex}
            setBetaIndex={setBetaIndex}
            getSelectedTicker={getSelectedTicker}
            selectedId={selectedId}
            isTotalPortfolio={isTotalPortfolio}
            betaId={betaId}
            setBetaId={setBetaId}
            setIndexDrop={setIndexDrop}
            setMultiDrop={setMultiDrop}
            originalCurrentUI={originalCurrentUI}
            checkBeta={checkBeta}
          />
        ) : null}
        {!isEmpty(results) || !isEmpty(investment) ? (
          <Foot
            results={results}
            investment={investment}
            rangeVal={rangeVal}
            isSelectIndex={isSelectIndex}
            isSelectMultiple={isSelectMultiple}
            openModal={openModal}
            handleModal={handleModal}
            indexDrop={indexDrop}
            multiDrop={multiDrop}
            isTotalPortfolio={isTotalPortfolio}
            betaId={betaId}
            originalCurrentUI={originalCurrentUI}
            checkBeta={checkBeta}
            editHoldingModal={editHoldingModal}
            setEditHoldingModal={setEditHoldingModal}
            handleEditModal={handleEditModal}
            handleHedgeModify={handleHedgeModify}
            handleModifyPortfolio={handleModifyPortfolio}
          />
        ) : null}
      </div>
    </div>
  );
}

function Options({
  hedge,
  rangeVal,
  setRangeVal,
  handleSelectIndex,
  handleSelectMultiple,
  uniqueIndexNames,
  relatedOptions,
  handleIndexChange,
  handleRelatedChange,
  indexData,
  currentMulti,
  setCurrentMulti,
  betaIndex,
  setBetaIndex,
  getSelectedTicker,
  selectedId,
  betaId,
  setBetaId,
  setIndexDrop,
  setMultiDrop,
  originalCurrentUI,
  checkBeta,
}) {
  const { options } = hedge;

  const { getHedgeData, isNewRow, editHolding, modifyHedge, getHoldingLoader } =
    useHedgingContext();

  const { isAuthenticated } = useAuth0();

  const authorizeHedgeData =
    isAuthenticated &&
    !!getHedgeData?.hedgeholding?.ID &&
    !!getHedgeData?.hedgeholding?.HEDGE_PERCENT;

  useEffect(() => {
    setRangeVal(
      authorizeHedgeData && !isNewRow
        ? getHedgeData?.hedgeholding?.HEDGE_PERCENT
        : Math.floor(getHedgeData?.hedgeholding?.HEDGE_PERCENT) == 0
        ? 50
        : 50
    );
  }, [
    authorizeHedgeData,
    getHedgeData?.hedgeholding?.HEDGE_PERCENT,
    isNewRow,
    setRangeVal,
  ]);

  const totalPortfolio = getHedgeData?.holdings?.reduce((acc, cv) => {
    return acc + parseFloat(cv.VALUE || 0);
  }, 0);

  const value =
    getHedgeData?.hedgeholding?.TOTAL_BETA_EXPOSURE / totalPortfolio;
  const betaRoundedValue = parseFloat(value.toFixed(2));

  return !originalCurrentUI ? (
    <div className="container">
      {!getHoldingLoader ? (
        <div className="my-32 mt-8 grid grid-cols-1 gap-x-28 gap-y-16 lg:grid-cols-[28%_28%_1fr]">
          {map(
            options,
            (
              { pill, info, description, list, range, isIndex, isDropdown },
              i
            ) => {
              const nullData = [{ label: "-" }];

              return (
                <div
                  className="flex flex-col justify-between"
                  key={`${pill?.toString().split(" ").join("-")}-${i}`}
                >
                  <div>
                    <Tooltip info={info} size="lg">
                      <span className="inline-flex items-center gap-x-4 rounded-[38px] bg-secondary p-8 pr-16 font-mazzardSemiBold text-2xs text-white">
                        <div className="flex h-20 w-20 items-center justify-center rounded-full bg-white text-secondary">
                          <div className="m-auto">{i + 1 + 1}</div>
                        </div>
                        <span>{pill}</span>
                      </span>
                    </Tooltip>
                    <div className="mt-16">
                      <HF>{description}</HF>
                    </div>
                  </div>
                  {!isEmpty(indexData) ? (
                    isIndex ? (
                      isDropdown &&
                      // (authorizeHedgeData && !isNewRow && !editHolding ? (
                      (authorizeHedgeData && !isNewRow && !modifyHedge ? (
                        <div className="relative z-10 mt-16 flex w-full items-center gap-x-8 overflow-hidden bg-grey-200 p-12 text-xs">
                          <span className="font-mazzardSemiBold ">
                            {getHedgeData?.hedgeholding?.INDEX_NAME.includes(
                              "Nasdaq"
                            )
                              ? getHedgeData?.hedgeholding?.INDEX_NAME?.toUpperCase()
                              : getHedgeData?.hedgeholding?.INDEX_NAME}
                          </span>
                          <span className="pr-24">
                            {`(${betaRoundedValue} beta) `}
                          </span>
                        </div>
                      ) : (
                        <DropdownInternalIndex
                          list={uniqueIndexNames}
                          className="mt-16 w-full"
                          event={handleIndexChange}
                          currentMulti={currentMulti}
                          betaIndex={betaIndex}
                          setBetaIndex={setBetaIndex}
                          setCurrentMulti={setCurrentMulti}
                          indexData={indexData}
                          getSelectedTicker={getSelectedTicker}
                          selectedId={selectedId}
                          setBetaId={setBetaId}
                          betaId={betaId}
                          setIndexDrop={setIndexDrop}
                          checkBeta={checkBeta}
                        />
                      ))
                    ) : (
                      isDropdown &&
                      (authorizeHedgeData &&
                      !isNewRow &&
                      !editHolding &&
                      !modifyHedge ? (
                        <div className="relative z-10 mt-16 flex w-full items-center justify-between overflow-hidden bg-grey-200 p-12 font-mazzardSemiBold text-xs">
                          {`${getHedgeData?.hedgeholding?.MULTIPLIER}x`}
                        </div>
                      ) : (
                        <DropdownInternalMultiple
                          list={relatedOptions}
                          className="mt-16 w-full"
                          event={handleRelatedChange}
                          currentMulti={currentMulti}
                          setCurrentMulti={setCurrentMulti}
                          betaIndex={betaIndex}
                          setBetaIndex={setBetaIndex}
                          getSelectedTicker={getSelectedTicker}
                          setMultiDrop={setMultiDrop}
                          checkBeta={checkBeta}
                        />
                      ))
                    )
                  ) : indexData?.length !== 0 ? (
                    <DropdownInternalIndex
                      list={nullData}
                      className="mt-16 w-full"
                    />
                  ) : null}

                  {range ? (
                    <RangeSlider
                      setRangeVal={setRangeVal}
                      rangeVal={Math.floor(rangeVal)}
                    />
                  ) : null}
                </div>
              );
            }
          )}
        </div>
      ) : (
        <div className="mt-28">
          <HoldingLoader />
        </div>
      )}
    </div>
  ) : null;
}

function RangeSlider(props) {
  const { rangeVal, setRangeVal } = props;
  const {
    handleRangeData,
    isNewRow,
    getAllPortfolio,
    getHedgeData,
    editHolding,
    handleHoldingShow,
    modifyHedge,
    handleModifyRange,
    modifyRange,
    rangeData,
    handleIsDropChange,
  } = useHedgingContext();

  const { isAuthenticated } = useAuth0();

  const hedgeHoldingBool =
    (!isEmpty(getHedgeData?.hedgeholding) && isAuthenticated && !isNewRow) ||
    isNewRow ||
    !isAuthenticated;

  const handleInput = (e) => {
    setRangeVal(e);
    handleRangeData(e);
    handleHoldingShow(false);
    handleModifyRange(true);
    handleIsDropChange(true);
  };

  useEffect(() => {
    if (modifyHedge && !modifyRange) {
      handleRangeData(getHedgeData?.hedgeholding?.HEDGE_PERCENT);
    } else {
      handleRangeData(rangeData);
    }
  }, [
    getHedgeData?.hedgeholding?.HEDGE_PERCENT,
    handleRangeData,
    modifyHedge,
    modifyRange,
    rangeData,
  ]);

  useEffect(() => {
    if ((editHolding && !isEmpty(getHedgeData?.hedgeholdings)) || modifyHedge) {
      setRangeVal(Math.round(getHedgeData?.hedgeholding?.HEDGE_PERCENT));
    }
  }, [
    editHolding,
    getHedgeData?.hedgeholding?.HEDGE_PERCENT,
    getHedgeData?.hedgeholdings,
    modifyHedge,
    setRangeVal,
  ]);

  const sliderMax = rangeVal == 0 ? 100 : Math.ceil(rangeVal / 100) * 100;

  return (
    <div className="mt-16">
      <div className="range-slider flex items-center gap-x-4 bg-grey-200 px-4 py-12 text-sm">
        <span>0%</span>
        <RS
          className="mx-auto w-[95%]"
          min={0}
          max={sliderMax}
          initialValue={hedgeHoldingBool ? rangeVal : 50}
          handleLabel={`${rangeVal}%`}
          onChange={handleInput}
          tooltip={false}
          step={1}
          withLabel={true}
          disabled={
            !isEmpty(getAllPortfolio) &&
            isAuthenticated &&
            !isNewRow &&
            !modifyHedge
              ? // !editHolding
                true
              : false
          }
        />
        <span>{sliderMax}%</span>
      </div>
    </div>
  );
}

function Foot(props) {
  const {
    results,
    investment,
    rangeVal,
    isSelectIndex,
    isSelectMultiple,
    openModal,
    handleModal,
    indexDrop,
    multiDrop,
    isTotalPortfolio,
    originalCurrentUI,
    editHoldingModal,
    setEditHoldingModal,
    handleEditModal,
    handleHedgeModify,
    handleModifyPortfolio,
  } = props;

  const {
    isHoldings,
    handleInvestment,
    selectedTickerBeta,
    handleSelectedTickerBeta,
    getHedgeData,
    isNewRow,
    editHolding,
    handleIsTotalPortfolio,
    isSelectedBeta,
    handleBetaValue,
    handleIsDropBeta,
    holdingShow,
    tableData,
    handleIsSelectBeta,
    handleHedgePercentage,
    currentHedgeValue,
    getTickerBeta,
    modifyHedge,
    handleHedgeModal,
    handleModifyHedge,
    betaValue,
    investmentLoader,
    newInverseEtf,
    getHoldingLoader,
    targetRef,
    handleModifyRange,
    handleWarning,
    handleUpdateDropdown,
    updateDropdown,
    rebalanceNew,
    getIndexData,
    updateModifyHedge,
    handleGetTickerBeta,
    handleSelectMultiple,
    handleSelectIndex,
    editTableData,
    getTickerResponse,
    handleEditBetaValue,
    handleIsDropChange,
    handleChangeModifyDropdown,
    handleResetCode,
    handleChangeMultiplier,
  } = useHedgingContext();

  const { isAuthenticated } = useAuth0();

  const totalHedgeExpo = useMemo(
    () => betaValue * (rangeVal / 100),
    [betaValue, rangeVal]
  );

  const totalUnhedgeExpo = useMemo(
    () => betaValue - totalHedgeExpo,
    [betaValue, totalHedgeExpo]
  );

  const handleUpdateHedgeModal = () => {
    handleHedgeModal(true);
  };

  const handleHedgeRebalanace = () => {
    handleModifyHedge(false);
    handleModifyRange(false);
    handleUpdateDropdown(false);
    handleSelectMultiple(getHedgeData?.hedgeholding?.MULTIPLIER);
    handleSelectIndex(getHedgeData?.hedgeholding?.INDEX_NAME);
    handleIsDropChange(false);
    handleChangeModifyDropdown(false);
    handleResetCode(false);
    handleChangeMultiplier(false);
  };

  const getApiData = updatedPortfolioData(getHedgeData?.holdings);

  const uniqueGetHedgeData = Array.from(
    new Set(getApiData?.map((item) => item.TICKER))
  ).map((ticker) => getApiData.find((item) => item.TICKER === ticker));

  const uniqueTableData = Array.from(
    new Set(tableData?.map((item) => item.TICKER))
  ).map((ticker) => tableData.find((item) => item.TICKER === ticker));

  const authorizeHedgeData =
    isAuthenticated && !!getHedgeData?.hedgeholding?.ID && !isNewRow;

  const hedgeHoldingBool =
    (!isEmpty(getHedgeData?.hedgeholding) && isAuthenticated && !isNewRow) ||
    isNewRow ||
    !isAuthenticated ||
    (isAuthenticated && !isNewRow);

  const totalEditPortfolio = useMemo(() => {
    if (
      editHolding ||
      (isNewRow && isAuthenticated) ||
      !isAuthenticated ||
      modifyHedge
    ) {
      return updatedPortfolioData(getHedgeData?.holdings)?.reduce((acc, cv) => {
        return acc + parseFloat(cv.VALUE || 0);
      }, 0);
    }
  }, [
    editHolding,
    getHedgeData?.holdings,
    isAuthenticated,
    isNewRow,
    modifyHedge,
  ]);

  useEffect(() => {
    if ((!isEmpty(getHedgeData?.holdings) && editHolding) || modifyHedge) {
      handleIsTotalPortfolio(totalEditPortfolio);
    }
  }, [
    editHolding,
    getHedgeData?.holdings,
    handleIsTotalPortfolio,
    modifyHedge,
    totalEditPortfolio,
  ]);

  const getTickerBetavalue = updateDropdown
    ? isSelectedBeta
    : getHedgeData?.hedgeholding?.INDEX_TICKER;

  // This is for Modify Flow
  useEffect(() => {
    if (!isEmpty(getHedgeData?.holdings) && !editHolding && !isNewRow) {
      const totalBetaValue = uniqueGetHedgeData?.reduce((acc, curr) => {
        // Check if the current object (curr) has the key that matches isSelectedBeta
        if (curr.hasOwnProperty(`beta_${getTickerBetavalue?.toLowerCase()}`)) {
          const value = parseFloat(curr.VALUE || 0);
          const selectedBeta = parseFloat(
            curr[`beta_${getTickerBetavalue?.toLowerCase()}`] || 0
          );
          return acc + value * selectedBeta;
        } else {
          // Handle the case where isSelectedBeta key is not found in the current object
          const value = parseFloat(curr.VALUE || 0);
          return acc + value * parseFloat(curr.beta_gspc_indx || 0);
        }
      }, 0);

      if (!!totalBetaValue) {
        handleBetaValue(totalBetaValue);
      }
    }

    // This is for Edit Flow
    if (editHolding) {
      const totalEditBetaValue = editTableData?.reduce((acc, curr) => {
        // Check if the current object (curr) has the key that matches isSelectedBeta
        if (curr.hasOwnProperty(`beta_${getTickerBetavalue?.toLowerCase()}`)) {
          // Ensure curr?.VALUE is a string before using .replace()
          const valueString = curr?.VALUE?.toString() || "0"; // Convert to string or default to "0"
          const value = Number(valueString.replace(/,/g, ""));

          const selectedBeta = parseFloat(
            curr[`beta_${getTickerBetavalue?.toLowerCase()}`] || 0
          );
          return acc + value * selectedBeta;
        } else {
          // Handle the case where isSelectedBeta key is not found in the current object
          const value = parseFloat(curr.VALUE || 0);
          return acc + value * parseFloat(curr.beta_gspc_indx || 0);
        }
      }, 0);

      if (!!totalEditBetaValue) {
        handleEditBetaValue(totalEditBetaValue);
      }

      const totalBetaValue = uniqueGetHedgeData?.reduce((acc, curr) => {
        // Check if the current object (curr) has the key that matches isSelectedBeta
        if (curr.hasOwnProperty(`beta_${getTickerBetavalue?.toLowerCase()}`)) {
          // Ensure curr?.VALUE is a string before using .replace()
          const valueString = curr?.VALUE?.toString() || "0"; // Convert to string or default to "0"
          const value = Number(valueString.replace(/,/g, ""));

          const selectedBeta = parseFloat(
            curr[`beta_${getTickerBetavalue?.toLowerCase()}`] || 0
          );
          return acc + value * selectedBeta;
        } else {
          // Handle the case where isSelectedBeta key is not found in the current object
          const value = parseFloat(curr.VALUE || 0);
          return acc + value * parseFloat(curr.beta_gspc_indx || 0);
        }
      }, 0);

      if (!!totalBetaValue) {
        handleBetaValue(totalBetaValue);
      }
    }

    // This is not for Modify Flow and Edit flow
    if (
      // (!isEmpty(getHedgeData?.holdings) && !editHolding) ||
      isNewRow ||
      modifyHedge
    ) {
      const totalBetaValue = uniqueTableData?.reduce((acc, curr) => {
        // Check if the current object (curr) has the key that matches isSelectedBeta
        if (curr.hasOwnProperty(`beta_${isSelectedBeta?.toLowerCase()}`)) {
          const value = parseFloat(curr.VALUE || 0);
          const selectedBeta = parseFloat(
            curr[`beta_${isSelectedBeta?.toLowerCase()}`] || 0
          );
          return acc + value * selectedBeta;
        } else {
          // Handle the case where isSelectedBeta key is not found in the current object
          const value = parseFloat(curr.VALUE || 0);
          return acc + value * parseFloat(curr.beta_gspc_indx || 0);
        }
      }, 0);

      if (!!totalBetaValue && !modifyHedge) {
        handleBetaValue(totalBetaValue);
      }
    }
  }, [
    editHolding,
    editTableData,
    getHedgeData?.holdings,
    getTickerBetavalue,
    handleBetaValue,
    handleEditBetaValue,
    isNewRow,
    isSelectedBeta,
    modifyHedge,
    uniqueGetHedgeData,
    uniqueTableData,
  ]);

  const betaValues = useMemo(() => {
    const obj = {
      afty: "afty",
      dja_indx: "dja_indx",
      eem: "eem",
      efa: "efa",
      gspc_indx: "gspc_indx",
      iwm: "iwm",
      mid_indx: "mid_indx",
      ndx_indx: "ndx_indx",
    };

    let values = [];

    for (const key in obj) {
      const totalBetaValue = updatedPortfolioData(
        getHedgeData?.holdings
      )?.reduce((acc, curr) => {
        if (curr.hasOwnProperty(`beta_${key}`)) {
          const value = parseFloat(curr.VALUE || 0);
          const selectedBeta = parseFloat(curr[`beta_${key}`] || 0);
          return acc + value * selectedBeta;
        } else {
          const value = parseFloat(curr.VALUE || 0);
          return acc + value * parseFloat(curr[`beta_${key}`] || 0);
        }
      }, 0);

      values = [...values, { name: key, totalBetaValue: totalBetaValue }];
    }
    return values;
  }, [getHedgeData?.holdings]);

  useEffect(() => {
    if ((!isEmpty(getHedgeData?.holdings) && editHolding) || modifyHedge) {
      if (!isEmpty(betaValues)) {
        handleIsDropBeta(betaValues);
      }
    }
  }, [
    betaValues,
    editHolding,
    getHedgeData?.holdings,
    handleIsDropBeta,
    modifyHedge,
  ]);

  useEffect(() => {
    if (editHolding && !isEmpty(getHedgeData?.hedgeholding)) {
      handleSelectedTickerBeta(selectedTickerBeta);
    }
  }, [
    editHolding,
    getHedgeData?.hedgeholding,
    handleSelectedTickerBeta,
    holdingShow,
    selectedTickerBeta,
  ]);

  useEffect(() => {
    handleSelectedTickerBeta(
      selectedTickerBeta == -1 ? -0.9958 : selectedTickerBeta
    );
  });

  useEffect(() => {
    if (!modifyHedge && !updateDropdown && !isNewRow) {
      handleIsSelectBeta((prev) =>
        !isEmpty(getHedgeData?.hedgeholding)
          ? getHedgeData?.hedgeholding?.INDEX_TICKER
          : prev
      );
    }
  }, [
    getHedgeData?.hedgeholding,
    handleIsSelectBeta,
    isNewRow,
    modifyHedge,
    updateDropdown,
  ]);

  const betaData =
    !updateDropdown && updateModifyHedge
      ? getHedgeData?.hedgeholding?.CURRENT_INDEX_BETA
      : getTickerBeta;

  const requireInvestment = useMemo(
    () =>
      Number(
        Math.abs(
          Number(parseFloat(totalHedgeExpo?.toFixed(2) || 0)) / getTickerBeta
        ).toFixed(2)
      ),
    [getTickerBeta, totalHedgeExpo]
  );

  useEffect(() => {
    handleGetTickerBeta(betaData);
  }, [betaData, handleGetTickerBeta]);

  useEffect(() => {
    handleInvestment(Number(requireInvestment));
  }, [handleInvestment, requireInvestment]);

  const currentHedgeExposure =
    currentHedgeValue *
    Math.abs(getHedgeData?.hedgeholding?.CURRENT_INDEX_BETA);

  const currentTotalBetaExposure = betaValue + currentHedgeExposure;

  const totalCurruntUnhedgeExpo = useMemo(
    () => betaValue - currentHedgeExposure,
    [betaValue, currentHedgeExposure]
  );

  //// ---------This is for post request data myend--------- //////////
  const [shData, setShData] = useState(0);

  useEffect(() => {
    // Function to handle fetching data
    mutate(apiKeys["hedgeTickerListKey"], () =>
      apiGetWithoutTokenRequest(`api/getindexticker?ticker=SH`)
    )
      .then((data) => {
        let getCurrRate = data;
        setShData(getCurrRate); // Set the data in state
      })
      .catch((error) => {
        console.error(error); // Handle errors here
      });
  }, []); // Empty array ensures this runs only once when the component mounts

  const targetHedgeExposure =
    (Math.floor(getHedgeData?.hedgeholding?.HEDGE_PERCENT) / 100) * betaValue;

  const requireHedgeHolding =
    targetHedgeExposure /
    Math.abs(getHedgeData?.hedgeholding?.CURRENT_INDEX_BETA);

  const tickerData =
    getIndexData?.market_close_value ?? shData?.market_close_value;

  const roundRequireInvestment = Number(
    (totalHedgeExpo / Math.abs(getTickerBeta)).toFixed(5)
  );

  let REQUIRED_INVESTMENT = roundRequireInvestment;

  if (editHolding) {
    REQUIRED_INVESTMENT = getHedgeData?.hedgeholding?.REQUIRED_INVESTMENT;
  } else if (rebalanceNew) {
    REQUIRED_INVESTMENT = requireHedgeHolding;
  }

  let rebalnceCheck;

  if (rebalanceNew) {
    rebalnceCheck = getHedgeData?.hedgeholding?.CURRENT_INDEX_RATE;
  } else {
    rebalnceCheck = tickerData;
  }

  // const hedgeUnits = Number((REQUIRED_INVESTMENT / rebalnceCheck).toFixed(10));
  let hedgeUnits = Number((REQUIRED_INVESTMENT / rebalnceCheck).toFixed(10));

  const currentHedgePercentageMyEnd =
    ((hedgeUnits * tickerData * Math.abs(getTickerBeta)) / betaValue) * 100;

  let PostObj = {
    marketClosedvalue: tickerData,
    hedgeUnits: hedgeUnits,
    requireInvestment: REQUIRED_INVESTMENT,
    betaValue: betaValue,
    hedgeExposure: totalHedgeExpo,
    tickerBeta: getTickerBeta,
    rangeValue: rangeVal,
    currentHedgePercentageMyEnd: Number(currentHedgePercentageMyEnd.toFixed(4)),
  };
  //// ---------This is for post request data myend--------- //////////

  // const apiExposure = getHedgeData?.hedgeholding?.HEDGED_EXPOSURE;

  const apiExposure =
    getHedgeData?.hedgeholding?.HEDGED_UNITS *
    getHedgeData?.hedgeholding?.CURRENT_INDEX_RATE *
    Math.abs(getHedgeData?.hedgeholding?.CURRENT_INDEX_BETA);

  const currentHedgePercentage = (apiExposure / betaValue) * 100;

  const curruntRequireInvestment = useMemo(
    () =>
      Number(
        Math.abs(
          Number(parseFloat(currentHedgeExposure?.toFixed(2) || 0)) /
            selectedTickerBeta
        ).toFixed(2)
      ),
    [currentHedgeExposure, selectedTickerBeta]
  );

  useEffect(() => {
    handleHedgePercentage(currentHedgePercentage);
  }, [handleHedgePercentage, currentHedgePercentage]);

  // const negativeTickers = tableData.reduce((acc, cv) => {
  //   if (cv.beta_gspc_indx < 0) {
  //     acc.push(cv.ticker);
  //   }
  //   return acc;
  // }, []);

  const isButtonEnabled =
    !!betaValue &&
    !investmentLoader &&
    !handleWarning &&
    betaValue > 0 &&
    ((!isAuthenticated && !isNewRow) ||
      (isAuthenticated && isNewRow) ||
      (!!betaValue && isAuthenticated));

  const betaNeagative = negativeBetaError(getHedgeData, editTableData);

  return (
    <div
      className={`border-t-4 border-secondary bg-grey-300 pt-32 ${
        betaValue < 0 || betaNeagative < 0 ? "pb-80" : "pb-48"
      }`}
    >
      <div className="container">
        {!getHoldingLoader ? (
          <div
            className={clsx(
              "grid grid-cols-1 gap-x-24 gap-y-32 lg:grid-cols-2",
              {
                "lg:grid-cols-[1fr_40%]": originalCurrentUI,
                "lg:grid-cols-2": !originalCurrentUI,
              }
            )}
          >
            {originalCurrentUI ? (
              <Rebalancing />
            ) : (
              <>
                {!isEmpty(results) ? (
                  <div className="flex flex-col items-stretch justify-between">
                    {results?.title ? (
                      <LineTitle text={results?.title} container={false} />
                    ) : null}
                    <div className="grid grid-cols-1 items-end gap-x-20 gap-y-32 pt-12 lg:grid-cols-3">
                      <div className="flex flex-col justify-between self-stretch">
                        <Tooltip
                          info={`
                        Total beta is calculated by multiplying the value of your sample portfolio by its weighted beta value. 
                        Click <a aria-label="See related disclosures about hedging with ETFs" target='_blank' href="${config?.baseUrl}/disclosures" aria-label="View disclosures">here</a> to view additional details on how beta is calculated.
                      `}
                          size="lg"
                        >
                          <div className="text-2xs">Total Beta Exposure</div>
                        </Tooltip>
                        <div className="mt-8 border-b border-grey-400 p-8 pl-0 font-mazzardSemiBold text-sm">
                          {`$${
                            authorizeHedgeData && !editHolding && !modifyHedge
                              ? parseFloat(
                                  currentTotalBetaExposure?.toFixed(2) || 0
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : formatDecimalNumber(
                                  hedgeHoldingBool && betaValue > 0
                                    ? betaValue
                                    : 0
                                )
                          }`}
                        </div>
                      </div>
                      <div className="flex flex-col justify-between self-stretch">
                        <Tooltip
                          info="The estimated beta exposure that will be hedged by your selections."
                          size="lg"
                        >
                          <div className="text-2xs">Hedged Exposure</div>
                        </Tooltip>
                        <div className="mt-8 border-b border-grey-400 p-8 pl-0 font-mazzardSemiBold text-sm">
                          {`$${
                            authorizeHedgeData && !editHolding && !modifyHedge
                              ? formatDecimalNumber(currentHedgeExposure)
                              : formatDecimalNumber(
                                  hedgeHoldingBool && totalHedgeExpo > 0
                                    ? totalHedgeExpo
                                    : 0
                                )
                          }`}
                        </div>
                      </div>
                      <div className="flex flex-col justify-between self-stretch">
                        <Tooltip
                          info="Estimated beta exposure remaining after your selections."
                          size="lg"
                        >
                          <div className="text-2xs">Unhedged Exposure</div>
                        </Tooltip>
                        <div className="mt-8 border-b border-grey-400 p-8 pl-0 font-mazzardSemiBold text-sm">
                          {`$${
                            authorizeHedgeData && !editHolding && !modifyHedge
                              ? parseFloat(
                                  totalCurruntUnhedgeExpo.toFixed(2) || 0
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : formatDecimalNumber(
                                  hedgeHoldingBool && totalUnhedgeExpo > 0
                                    ? totalUnhedgeExpo
                                    : 0
                                )
                          }`}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {!isEmpty(investment) ? (
                  <div className="relative flex flex-col items-stretch justify-between">
                    {investment?.title ? (
                      <LineTitle text={investment?.title} container={false} />
                    ) : null}
                    <div className="grid grid-cols-1 items-end gap-x-20 gap-y-32 pt-12 lg:grid-cols-3">
                      <div className="flex flex-col justify-between self-stretch">
                        <Tooltip
                          info="Hypothetical cost required to purchase your selected hedge."
                          size="lg"
                        >
                          <div className="text-2xs">Required Investment</div>
                        </Tooltip>
                        <div className="mt-8 border-b border-grey-400 p-8 pl-0 font-mazzardSemiBold text-sm">
                          {!investmentLoader ? (
                            `$${
                              hedgeHoldingBool
                                ? authorizeHedgeData &&
                                  !editHolding &&
                                  !modifyHedge
                                  ? formatDecimalNumber(
                                      curruntRequireInvestment || 0
                                    )
                                  : totalHedgeExpo > 0 ||
                                    Math.sign(totalHedgeExpo) === -1
                                  ? `${
                                      requireInvestment === Infinity ||
                                      totalHedgeExpo < 0
                                        ? "0.00"
                                        : formatDecimalNumber(requireInvestment)
                                    }`
                                  : "0.00"
                                : formatDecimalNumber(0)
                            }`
                          ) : (
                            <ButtonLoader />
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col justify-between self-stretch">
                        <Tooltip
                          info="A hypothetical investment reflecting your index and multiple level choices."
                          size="lg"
                        >
                          <div className="text-2xs">Inverse ETF</div>
                        </Tooltip>
                        <div className="mt-8 border-b border-grey-400 p-8 pl-0 font-mazzardSemiBold text-sm">
                          {hedgeHoldingBool ? (
                            <>
                              {(authorizeHedgeData &&
                                !editHolding &&
                                newInverseEtf) ||
                              (!isSelectMultiple && editHolding) ||
                              newInverseEtf
                                ? `${getHedgeData?.hedgeholding?.MULTIPLIER}x`
                                : !!isSelectMultiple
                                ? isSelectMultiple
                                : "-1x"}{" "}
                              {(authorizeHedgeData &&
                                !editHolding &&
                                newInverseEtf) ||
                              (!isSelectIndex && editHolding) ||
                              newInverseEtf
                                ? getHedgeData?.hedgeholding?.INDEX_NAME.includes(
                                    "Nasdaq"
                                  )
                                  ? getHedgeData?.hedgeholding?.INDEX_NAME?.toUpperCase()
                                  : getHedgeData?.hedgeholding?.INDEX_NAME
                                : isSelectIndex?.includes("Nasdaq")
                                ? isSelectIndex?.toUpperCase()
                                : !!isSelectIndex
                                ? isSelectIndex
                                : "S&P 500"}
                            </>
                          ) : (
                            "-1x S&P 500"
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col justify-between gap-4">
                        {modifyHedge ? (
                          <>
                            <button
                              className="cta btn btn-primary inline-block px-24 py-8"
                              onClick={handleUpdateHedgeModal}
                            >
                              Update Hedge
                            </button>
                            <button
                              className="text-blue-200 underline"
                              onClick={handleHedgeRebalanace}
                            >
                              Cancel
                            </button>
                          </>
                        ) : !isButtonEnabled ||
                          handleWarning ||
                          !getTickerResponse ? (
                          <DisableState
                            info="You must fill out your portfolio before setting a hedge"
                            tooltipLeft={true}
                          >
                            <button
                              className={`cta btn btn-primary inline-block px-48 py-8 leading-7 ${
                                isButtonEnabled && getTickerResponse
                                  ? "cursor-pointer"
                                  : "cursor-not-allowed opacity-50"
                              } `}
                              disabled={
                                !isButtonEnabled ||
                                handleWarning ||
                                !getTickerResponse
                              }
                            >
                              Set Hedge
                            </button>
                          </DisableState>
                        ) : (
                          <button
                            className={`cta btn btn-primary inline-block px-24 py-8 leading-7 ${
                              isButtonEnabled && getTickerResponse
                                ? "cursor-pointer"
                                : "cursor-not-allowed opacity-50"
                            } `}
                            onClick={handleModal}
                            disabled={
                              !isButtonEnabled ||
                              handleWarning ||
                              !getTickerResponse
                            }
                          >
                            Set Hedge
                          </button>
                        )}

                        <Modal
                          openModal={openModal}
                          handleModal={handleModal}
                          indexDrop={indexDrop}
                          multiDrop={multiDrop}
                          isTotalPortfolio={isTotalPortfolio}
                          betaValue={betaValue}
                          totalHedgeExpo={totalHedgeExpo}
                          totalUnhedgeExpo={totalUnhedgeExpo}
                          isHoldings={isHoldings}
                          requireInvestment={requireInvestment}
                          targetRef={targetRef}
                          currentHedgePercentage={currentHedgePercentage}
                          handleHedgeModify={handleHedgeModify}
                          handleEditModal={handleEditModal}
                          handleModifyPortfolio={handleModifyPortfolio}
                        />

                        <EditModal
                          openEditHoldingModal={editHoldingModal}
                          handleEditModal={handleEditModal}
                          setEditHoldingModal={setEditHoldingModal}
                          handleModifyPortfolio={handleModifyPortfolio}
                          handleHedgeModify={handleHedgeModify}
                        />
                      </div>
                    </div>
                    {betaValue < 0 || betaNeagative < 0 ? (
                      <div className="absolute -bottom-48 pt-8 text-red">
                        No hedge is required due to negative total beta exposure
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : null}
              </>
            )}
          </div>
        ) : (
          <HoldingLoader />
        )}
        {handleWarning && (
          <div className="my-12 text-red">
            * this ticker is not availble for now. kindly change or try again
            tommorrow.
          </div>
        )}
      </div>
    </div>
  );
}

function DropdownInternalIndex(props) {
  const {
    list,
    className,
    event,
    setCurrentMulti,
    getSelectedTicker,
    setIndexDrop,
    indexData,
    checkBeta,
  } = props;

  const {
    handleIsSelectBeta,
    handleIsDropChange,
    isDropBeta,
    isNewRow,
    getAllPortfolio,
    getHedgeData,
    editHolding,
    handleHoldingShow,
    modifyHedge,
    isTotalPortfolio,
    betaValue,
    handleInvestmentLoader,
    handleUpdateModifyHedge,
    handleNewInverseEtf,
    handleUpdateDropdown,
    updateDropdown,
    handleResetCode,
    handleChangeModifyDropdown,
    handleSelectMultiple,
    handleIndexDropLabel,
    indexDropLabel,
    handleSelectIndex,
    resetCode,
    changeMultiplier,
  } = useHedgingContext();

  const { isAuthenticated } = useAuth0();

  const [openDrop, setOpenDrop] = useState(false);

  const [betaTicker, setBetaTicker] = useState(
    !isEmpty(getHedgeData?.hedgeholding) && !isNewRow
      ? getHedgeData?.hedgeholding?.INDEX_TICKER
      : indexData?.[0].INDEX_TICKER
  );

  const isIndexList =
    modifyHedge && resetCode
      ? indexDropLabel
      : isNewRow && resetCode
      ? indexDropLabel
      : (!isEmpty(getHedgeData?.hedgeholding) && editHolding) ||
        (modifyHedge && !resetCode && !changeMultiplier)
      ? getHedgeData?.hedgeholding?.INDEX_NAME
      : indexDropLabel;
  const dropdown = useRef(null);

  const hedgeHoldingBool =
    (!isEmpty(getHedgeData?.hedgeholding) && isAuthenticated && !isNewRow) ||
    isNewRow ||
    !isAuthenticated ||
    (isAuthenticated && !isNewRow);

  const handleOpenDrop = () => {
    setOpenDrop((prev) => !prev);
  };

  const handleIndexDrop = (str, HEDGE_TICKER, INDEX_TICKER, MULTIPLIER) => {
    handleIndexDropLabel(str);
    handleSelectIndex(str);
    handleResetCode(true);
    setBetaTicker(INDEX_TICKER);
    setCurrentMulti(MULTIPLIER);
    getSelectedTicker(str, MULTIPLIER);
    handleIsDropChange(true);
    setIndexDrop(str);
    handleInvestmentLoader(true);
    handleUpdateModifyHedge(false);
    handleUpdateDropdown(true);
    handleChangeModifyDropdown(true);
    handleSelectMultiple(`${MULTIPLIER}x`);
  };

  useEffect(() => {
    if (!!betaTicker) {
      handleIsSelectBeta(updateDropdown ? betaTicker : "GSPC_INDX");
    }
  }, [betaTicker, handleIsSelectBeta, isNewRow, updateDropdown]);

  useEffect(() => {
    if (!openDrop) return;

    function handleClick(event) {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setOpenDrop(false);
      }
    }

    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, [openDrop]);

  const betaChange =
    typeof betaValue === "number" &&
    typeof isTotalPortfolio === "number" &&
    isTotalPortfolio !== 0
      ? betaValue / isTotalPortfolio
      : NaN;

  return (
    <div className="relative" ref={dropdown}>
      <button
        onClick={() =>
          !isEmpty(getAllPortfolio) &&
          isAuthenticated &&
          !isNewRow &&
          !editHolding &&
          !modifyHedge
            ? null
            : handleOpenDrop()
        }
        className={clsx(
          "hedge-index relative z-10 flex items-center justify-between overflow-hidden bg-grey-200 p-12  text-xs",
          className,
          {
            "cursor-not-allowed":
              !isEmpty(getAllPortfolio) &&
              isAuthenticated &&
              !isNewRow &&
              !editHolding &&
              !modifyHedge,
          }
        )}
      >
        <span className="flex gap-x-8">
          <span className="font-mazzardSemiBold">
            {hedgeHoldingBool
              ? isIndexList?.includes("Nasdaq")
                ? isIndexList?.toUpperCase()
                : isIndexList
              : "S&P 500"}
          </span>
          <span>
            {(!isNaN(betaChange) && hedgeHoldingBool) ||
            (!isEmpty(getHedgeData?.hedgeholding) &&
              isAuthenticated &&
              !isNewRow &&
              editHolding) ? (
              !!betaChange || modifyHedge ? (
                <span>{formatDecimalNumber(betaChange)} beta</span>
              ) : (
                <ButtonLoader />
              )
            ) : (
              <span>--</span>
            )}
          </span>
        </span>
        <Icon
          name="dropdown-caret"
          className={clsx(
            "origin-center transform justify-self-end transition duration-200 ease-out",
            { "!rotate-180": openDrop }
          )}
        />
      </button>
      {openDrop ? (
        <ul
          role="listbox"
          onClick={handleOpenDrop}
          className="absolute z-[11] mt-2 w-full rounded-md bg-white py-2 shadow-xl"
        >
          {list.map(
            (
              {
                INDEX_NAME,
                MULTIPLIER,
                ISMULTIPLIER,
                HEDGE_TICKER,
                INDEX_TICKER,
              },
              i
            ) => {
              // Find the beta value for the current INDEX_NAME
              const betaValueObj = isDropBeta?.find(
                (item) => item.name === INDEX_TICKER.toLowerCase()
              );

              // Extract totalBetaValue from the beta value object, default to 0 if not found
              const totalBetaValue = betaValueObj
                ? betaValueObj.totalBetaValue
                : 0;

              return (
                <li
                  role="listitem"
                  key={`${INDEX_NAME?.toString()?.split(" ").join("-")}-${i}`}
                >
                  <button
                    onClick={() => {
                      handleOpenDrop;
                      handleIndexDrop(
                        INDEX_NAME,
                        HEDGE_TICKER,
                        INDEX_TICKER,
                        MULTIPLIER
                      );
                      event(INDEX_NAME, MULTIPLIER, HEDGE_TICKER);
                      handleResetCode(true);
                      handleHoldingShow(false);
                      handleInvestmentLoader(true);
                      handleNewInverseEtf(false);
                    }}
                    href="#"
                    className="block flex w-full cursor-pointer gap-8 p-12 text-start text-sm hover:font-mazzardSemiBold"
                  >
                    <span>{INDEX_NAME}</span>
                    <span></span>{" "}
                    {isNaN(
                      formatDecimalNumber(totalBetaValue / isTotalPortfolio)
                    )
                      ? (0).toFixed(2)
                      : formatDecimalNumber(
                          totalBetaValue / isTotalPortfolio
                        )}{" "}
                    beta
                  </button>
                </li>
              );
            }
          )}
        </ul>
      ) : null}
    </div>
  );
}

function DropdownInternalMultiple(props) {
  const {
    list,
    className,
    event,
    setCurrentMulti,
    betaIndex,
    setBetaIndex,
    getSelectedTicker,
    setMultiDrop,
  } = props;

  const {
    isNewRow,
    getAllPortfolio,
    getHedgeData,
    editHolding,
    handleHoldingShow,
    modifyHedge,
    resetCode,
    handleInvestmentLoader,
    handleNewInverseEtf,
    handleUpdateModifyHedge,
    handleUpdateDropdown,
    handleIsDropChange,
    handleResetCode,
    handleChangeModifyDropdown,
    handleMultiplierDroplabel,
    multiplierDroplabel,
    changeModifyDropdown,
    handleChangeMultiplier,
    handleIndexDropLabel,
    handleSelectMultiple,
  } = useHedgingContext();

  const { isAuthenticated } = useAuth0();

  const [openDrop, setOpenDrop] = useState(false);
  const dropdown = useRef(null);

  const hedgeHoldingBool =
    (!isEmpty(getHedgeData?.hedgeholding) && isAuthenticated && !isNewRow) ||
    isNewRow ||
    !isAuthenticated ||
    (isAuthenticated && !isNewRow);

  const handleOpenDrop = () => {
    setOpenDrop((prev) => !prev);
  };

  const handleMultiplierDrop = (str, HEDGE_TICKER, MULTIPLIER, INDEX_NAME) => {
    handleIndexDropLabel(
      !resetCode && !isNewRow
        ? getHedgeData?.hedgeholding?.INDEX_NAME
        : INDEX_NAME
    );
    handleMultiplierDroplabel(str);
    handleSelectMultiple(str);
    handleResetCode(false);
    setCurrentMulti(MULTIPLIER);
    handleChangeMultiplier(true);
    setBetaIndex(betaIndex);
    getSelectedTicker(INDEX_NAME, MULTIPLIER);
    setMultiDrop(MULTIPLIER);
    handleInvestmentLoader(true);
    handleUpdateModifyHedge(false);
    handleUpdateDropdown(true);
    handleIsDropChange(true);
    handleChangeModifyDropdown(true);
  };

  useEffect(() => {
    if (resetCode) {
      // If resetCode is true, set the dropdown label to "–1x"
      handleMultiplierDroplabel("-1x");
    }
  }, [handleMultiplierDroplabel, resetCode]);

  useEffect(() => {
    if (!openDrop) return;

    function handleClick(event) {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setOpenDrop(false);
      }
    }

    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, [openDrop]);

  const multiplierDrop =
    modifyHedge && changeModifyDropdown
      ? multiplierDroplabel
      : (!isEmpty(getHedgeData?.hedgeholding) && editHolding) || modifyHedge
      ? `${getHedgeData?.hedgeholding?.MULTIPLIER}x`
      : isNewRow
      ? multiplierDroplabel
      : "-1x";

  return (
    <div className="relative" ref={dropdown}>
      <button
        onClick={() =>
          !isEmpty(getAllPortfolio) &&
          isAuthenticated &&
          !isNewRow &&
          !modifyHedge
            ? // !editHolding
              null
            : handleOpenDrop()
        }
        className={clsx(
          "multiplier-hedge relative z-10 flex items-center justify-between overflow-hidden bg-grey-200 p-12 font-mazzardSemiBold text-xs",
          className,
          {
            "cursor-not-allowed":
              !isEmpty(getAllPortfolio) &&
              isAuthenticated &&
              !isNewRow &&
              !modifyHedge,
          }
        )}
      >
        <span>{hedgeHoldingBool ? multiplierDrop : "-1x"}</span>
        <Icon
          name="dropdown-caret"
          className={clsx(
            "origin-center transform justify-self-end transition duration-200 ease-out",
            { "!rotate-180": openDrop }
          )}
        />
      </button>
      {openDrop ? (
        <ul
          role="listbox"
          onClick={handleOpenDrop}
          className="absolute z-[1] mt-2 w-full rounded-md bg-white py-2 shadow-xl"
        >
          {map(
            list,
            ({ INDEX_NAME, MULTIPLIER, ISMULTIPLIER, HEDGE_TICKER }, i) => {
              return (
                <li
                  role="listitem"
                  key={`${INDEX_NAME?.toString()?.split(" ").join("-")}-${i}`}
                >
                  <button
                    onClick={() => {
                      handleOpenDrop,
                        handleMultiplierDrop(
                          `${MULTIPLIER}x`,
                          HEDGE_TICKER,
                          MULTIPLIER,
                          INDEX_NAME
                        ),
                        event(INDEX_NAME, MULTIPLIER, HEDGE_TICKER);
                      handleHoldingShow(false);
                      handleInvestmentLoader(true);
                      handleNewInverseEtf(false);
                    }}
                    href="#"
                    className="block w-full cursor-pointer p-12 text-start text-sm hover:font-mazzardSemiBold"
                  >
                    {`${MULTIPLIER}x`}
                  </button>
                </li>
              );
            }
          )}
        </ul>
      ) : null}
    </div>
  );
}

function Modal(props) {
  const {
    openModal,
    handleModal,
    indexDrop,
    multiDrop,
    isTotalPortfolio,
    betaValue,
    totalUnhedgeExpo,
    totalHedgeExpo,
    targetRef,
    handleHedgeModify,
    // currentHedgePercentage,
    handleEditModal,
  } = props;

  const {
    handleHoldingShow,
    handleIsDropChange,
    handleIsHoldings,
    getHedgeData,
    hedgePercentage,
    currentHedgeValue,
    isRequiredInvestment,
    selectedTickerBeta,
    handleHedgeModal,
    modifyHedgeModal,
    rangeData,
    getTickerBeta,
    handleModifyHedge,
    isSelectIndex,
    isSelectMultiple,
    handleShowModal,
    handleModifyHedgeNew,
    handleTableData,
    handleScrollView,
    handleRecall,
    modifyRange,
    updateModifyHedge,
    handleGetTickerResponse,
    tableData,
  } = useHedgingContext();

  const newRequiredInvestment = isRequiredInvestment - currentHedgeValue;

  const handleModalClose = () => {
    handleHedgeModal(false);
  };

  const isAddHoldings = () => {
    handleModal();
    handleIsHoldings(true);
    handleIsDropChange(false);
    handleHoldingShow(true);
    handleShowModal((prev) => !prev);

    localStorage.setItem(
      "totalHoldings",
      isTotalPortfolio + isRequiredInvestment
    );
  };

  const handleScroll = () => {
    targetRef.current?.scrollIntoView({
      behavior: "smooth", // Smooth scrolling
      block: "start", // Align to the start of the element
    });
  };

  return openModal ? (
    <div className={clsx(BASE_CLASS)}>
      <div className="fixed left-0 top-0 z-[1200] flex h-full w-full items-center justify-center bg-black/80">
        <div className="relative grid min-h-[464px] w-[78vw] bg-white lg:w-[355px]">
          <div className="px-20 py-20 md:pt-64">
            <div className="h4 mb-16 leading-8 text-dark">Preview My Hedge</div>
            <div className="mb-16">
              <div className="grid grid-cols-[35%_1fr] gap-24 bg-grey-300 px-8 py-12 font-mazzardSemiBold">
                <div></div>
                <div className="font-mazzardSemiBold">Hedge Details</div>
              </div>
              <div className="grid grid-cols-[35%_1fr] grid-rows-3 gap-24 border-b border-grey-400 px-8 py-24">
                <div className="font-mazzardSemiBold">Inverse ETF</div>
                <div className="font-mazzardSemiBold">
                  {`${isSelectMultiple ? isSelectMultiple : "-1x"} ${
                    isSelectIndex ? isSelectIndex : "S&P 500"
                  }`}
                </div>
                <div className="font-mazzardSemiBold">Hedge %</div>
                <div className="font-mazzardSemiBold">{`${rangeData}%`}</div>
                <div className="font-mazzardSemiBold">Hedge $</div>
                <div className="font-mazzardSemiBold">{`$${formatDecimalNumber(
                  isRequiredInvestment
                )}`}</div>
              </div>
              <div className="grid grid-cols-[35%_1fr] gap-24 px-8 pt-24">
                <div className="pr-4 font-mazzardSemiBold">Summary</div>
                <div className="">
                  <HF>{`To set your hedge to <span class="font-mazzardSemiBold">${rangeData}%</span> you will need to purchase <span class="font-mazzardSemiBold">$${formatDecimalNumber(
                    isRequiredInvestment
                  )}</span> of <span class="font-mazzardSemiBold">${
                    isSelectMultiple ? isSelectMultiple : "-1x"
                  } ${
                    isSelectIndex ? isSelectIndex : "S&P 500"
                  } ETF.</span>`}</HF>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-16">
              <button
                className="cta btn btn-primary w-full font-semibold"
                onClick={() => {
                  isAddHoldings();
                }}
              >
                Accept
              </button>
            </div>
            <div className="absolute right-0 top-0 p-20">
              <button
                className="rounded-xl border border-solid border-black font-semibold"
                onClick={() => handleModal()}
                aria-label="Close modal"
              >
                <Icon
                  name="close"
                  className={clsx(
                    "h-[32px] w-[32px] origin-center transform justify-self-end transition duration-200 ease-out"
                  )}
                />
              </button>
            </div>
            <div className="absolute right-0 top-0 p-20">
              <button
                className="rounded-xl border border-solid border-black"
                onClick={() => handleModal()}
                aria-label="Close modal"
              >
                <Icon
                  name="close"
                  className={clsx(
                    "h-[32px] w-[32px] origin-center transform justify-self-end transition duration-200 ease-out"
                  )}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : modifyHedgeModal ? (
    <div className={clsx(BASE_CLASS)}>
      <div className="fixed left-0 top-0 z-[1200] flex h-full w-full items-center justify-center bg-black/80">
        <div className="relative grid min-h-[464px] w-[78vw] bg-white py-2 lg:w-[464px]">
          <div className="px-24 py-24 md:pt-72">
            <div className="h4 mb-24 font-mazzardSemiBold text-dark">
              Modify Hedge
            </div>
            <div className="mb-24">
              <div className="grid grid-cols-3 gap-16 bg-grey-200 px-8 py-12 font-mazzardSemiBold">
                <div></div>
                <div>Current</div>
                <div>New</div>
              </div>
              <div className="grid grid-cols-3 grid-rows-3 gap-16 border-b border-b border-grey-400 px-8 py-24">
                <div className="font-mazzardSemiBold">Inverse ETF</div>
                <div>
                  {`${getHedgeData?.hedgeholding?.MULTIPLIER}x ${getHedgeData?.hedgeholding?.INDEX_NAME}`}
                </div>
                <div>
                  {!!isSelectMultiple && !updateModifyHedge
                    ? `${isSelectMultiple ? isSelectMultiple : "-1x"} ${
                        isSelectIndex ? isSelectIndex : "S&P 500"
                      }`
                    : `${getHedgeData?.hedgeholding?.MULTIPLIER}x ${getHedgeData?.hedgeholding?.INDEX_NAME}`}
                </div>

                <div className="font-mazzardSemiBold">Hedge %</div>
                <div>{Math.round(modifyHedgePercent(getHedgeData))}%</div>
                <div>
                  {" "}
                  {modifyRange
                    ? rangeData
                    : Math.floor(getHedgeData?.hedgeholding?.HEDGE_PERCENT)}
                  %
                </div>
                <div className="font-mazzardSemiBold">Hedge $</div>
                <div>{`$${formatDecimalNumber(currentHedgeValue)}`}</div>
                <div>{`$${formatDecimalNumber(isRequiredInvestment)}`}</div>
              </div>
              <div className="grid grid-cols-3 gap-16 px-8 pb-12 pt-24">
                <div className="font-mazzardSemiBold">Summary</div>
                <div className="col-span-2">
                  {Number(
                    formatDecimalNumber(Math.abs(newRequiredInvestment))
                  ) === 0 ? (
                    "No Rebalance required."
                  ) : (
                    <HF>
                      {`Sell
                     <span class="font-mazzardSemiBold">${`$${formatDecimalNumber(
                       currentHedgeValue
                     )}`}</span> of <span class="font-mazzardSemiBold">${
                       getHedgeData?.hedgeholding?.MULTIPLIER
                     }x${" "}${
                       getHedgeData?.hedgeholding?.INDEX_NAME
                     }</span>. Buy <span class="font-mazzardSemiBold">$${formatDecimalNumber(
                       isRequiredInvestment
                     )}</span> of <span class="font-mazzardSemiBold">${
                       !!isSelectMultiple ? isSelectMultiple : "-1x"
                     } ${!!isSelectIndex ? isSelectIndex : "S&P 500"}</span>
                to set % Hedged to <span class="font-mazzardSemiBold">${Math.floor(
                  rangeData
                )}%</span>.`}
                    </HF>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-16">
              <button
                className="cta btn btn-primary w-full"
                onClick={handleEditModal}
                // onClick={handleHedgeModify}
              >
                Update Hedge
              </button>
            </div>
            <div className="absolute right-0 top-0 p-20">
              <button
                className="rounded-xl border border-solid border-black"
                onClick={() => handleModalClose()}
                aria-label="Close modal"
              >
                <Icon
                  name="close"
                  className={clsx(
                    "h-[32px] w-[32px] origin-center transform justify-self-end transition duration-200 ease-out"
                  )}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

function EditModal(props) {
  const {
    openEditHoldingModal,
    handleEditModal,
    setEditHoldingModal,
    handleHoldingModal,
    handleHedgeRebalance,
    handleModifyPortfolio,
    isModifingHedge,
    handleHedgeModify,
  } = props;
  const { updateDropLabel, loaderShow } = useHedgingContext();

  return openEditHoldingModal || isModifingHedge ? (
    <div className={clsx(BASE_CLASS)}>
      <div className="fixed left-0 top-0 z-[1200] flex h-full w-full items-center justify-center bg-black/80">
        <div className="relative grid min-h-[348px] w-[78vw] bg-white py-2 lg:w-[464px]">
          <div className="px-30 pb-24 pt-56">
            <div className="text-md">
              Save your changes to <strong>{updateDropLabel}</strong> or create
              a new holding list based on changes made.
            </div>
            <button
              className="cta btn btn-primary mt-24 inline-block w-full"
              onClick={handleModifyPortfolio}
            >
              {loaderShow ? <ButtonLoader /> : "Save"}
            </button>
            <button
              className="cta btn btn-secondary mt-24 inline-block w-full"
              onClick={handleHedgeModify}
            >
              Create New
            </button>

            <div className="absolute right-0 top-0 p-20">
              <button
                className="rounded-xl border border-solid border-black"
                onClick={() => handleEditModal()}
                aria-label="Close modal"
              >
                <Icon
                  name="close"
                  className={clsx(
                    "h-[32px] w-[32px] origin-center transform justify-self-end transition duration-200 ease-out"
                  )}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

function ButtonLoader() {
  return (
    <div className="w-[80px] max-w-sm rounded-md">
      <div className="flex animate-pulse space-x-4">
        <div className="space-y-6 py-1 flex-1">
          <div className="h-20 rounded bg-slate-300"></div>
        </div>
      </div>
    </div>
  );
}

function HoldingLoader() {
  return (
    <div className="mb-48 w-[100%] rounded-md">
      <div className="animate-pulse">
        <div className="mb-8 h-12 rounded bg-slate-300"></div>
        <div className="mb-8 h-12 rounded bg-slate-300"></div>
        <div className="mb-8 h-12 rounded bg-slate-300"></div>
        <div className="mb-8 h-12 rounded bg-slate-300"></div>
      </div>
    </div>
  );
}

export default SetHedge;
