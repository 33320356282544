import clsx from "classnames";
import isEmpty from "lodash.isempty";
import map from "lodash.map";
import { HtmlFragment as HF, Icon, Link } from "./*";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import config from "@/config";
import Image from "next/image";

const BASE_CLASS = "benefits";

function OnboardingModal({ onBoardingModal }) {
  const [openOnboardingModal, setOnboardingModal] = useState(false);
  const [active, setActive] = useState(0);

  const { isAuthenticated, isLoading } = useAuth0();

  // prvious logic : (!isAuthenticated && !isLoading) for modal

  useEffect(() => {
    if (!isAuthenticated || !isLoading) {
      const showModal = sessionStorage.getItem("showOnboardingModal");
      if (!showModal) {
        setOnboardingModal(true);
        sessionStorage.setItem("showOnboardingModal", "shown");
      }
    }
    // Add event listener for beforeunload
    const handleBeforeUnload = () => {
      sessionStorage.removeItem("showOnboardingModal");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isAuthenticated, isLoading]);

  const handleModal = () => {
    setOnboardingModal(false);
    sessionStorage.removeItem("showOnboardingModal");
  };

  return !isEmpty(onBoardingModal) ? (
    <div className={clsx(BASE_CLASS)}>
      <div
        className="cursor-pointer text-blue-200 underline"
        onClick={() => setOnboardingModal(true)}
      >
        {onBoardingModal.title}
      </div>
      {openOnboardingModal ? (
        <div className="fixed left-0 top-0 z-[1200] flex h-full w-full items-center justify-center bg-black/80">
          <div className="relative grid h-[90vh] w-[78vw] overflow-y-scroll bg-white py-2 lg:w-[1290px]">
            <div className="px-30 pt-24 md:pt-30 lg:px-96">
              <div className="mb-0 font-mazzard text-[48px] text-grey-600">
                {onBoardingModal.heading}
              </div>
              {/* <ul className="flex flex-col gap-x-40 border-b border-grey-400 md:flex-row">
                {map(
                  Array.isArray(onBoardingModal?.tabList)
                    ? onBoardingModal?.tabList
                    : [onBoardingModal?.tabList],
                  ({ title }, i) => {
                    return title ? (
                      <li
                        className={clsx("border-r border-white last:border-0", {
                          "mb-32 md:mb-0": i === 0,
                        })}
                        key={`${title?.toString()?.split(" ").join("-")}-${i}`}
                      >
                        <button
                          className={clsx("border-b-2 pb-8", {
                            "border-secondary text-primary": active === i,
                            "border-transparent text-grey-600": active !== i,
                          })}
                          onClick={() => setActive(i)}
                        >
                          {title}
                        </button>
                      </li>
                    ) : null;
                  }
                )}
              </ul> */}
              <div>
                {map(
                  Array.isArray(onBoardingModal?.tabList)
                    ? onBoardingModal?.tabList
                    : [onBoardingModal?.tabList],
                  (item, i) => {
                    return onBoardingModal.title ? (
                      <div
                        key={`${item?.title
                          ?.toString()
                          ?.split(" ")
                          .join("-")}-${i}`}
                        className={clsx({
                          block: active === i,
                          hidden: active !== i,
                        })}
                      >
                        {item?.getStarted ? (
                          <GetStarted
                            heading={item.heading}
                            subHeading={item?.subHeading}
                            paragraph={item.paragraph}
                            videoLink={item?.videoLink}
                            content={item?.content}
                            handleModal={handleModal}
                          />
                        ) : item?.betaExplained ? (
                          <BetaExplained
                            content={item?.content}
                            handleModal={handleModal}
                          />
                        ) : null}
                      </div>
                    ) : null;
                  }
                )}
              </div>

              <div className="absolute right-0 top-0 p-20">
                <button
                  className="rounded-xl border border-solid border-black"
                  onClick={() => handleModal()}
                  aria-label="Close modal"
                >
                  <Icon
                    name="close"
                    className={clsx(
                      "h-[32px] w-[32px] origin-center transform justify-self-end transition duration-200 ease-out"
                    )}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  ) : null;
}

function GetStarted(props) {
  let { heading, subHeading, paragraph, videoLink, content, handleModal } =
    props;

  return (
    <div>
      <div className="text-body-sm  border-dark pb-32">
        {subHeading && <div className="my-24">{subHeading}</div>}
        <ul>
          {map(Array.isArray(content) ? content : [content], (item, i) => {
            return (
              <li className="mb-8 flex gap-8 lg:gap-16" key={i}>
                <div className="w-12">{i + 1}.</div>
                <div>
                  <HF>{item.description}</HF>
                </div>
              </li>
            );
          })}
        </ul>
        {paragraph && (
          <div className="mt-16 font-light text-dark">{paragraph}</div>
        )}
      </div>

      {videoLink ? (
        <div className="relative h-0 pb-[56.25%]">
          <iframe
            src={videoLink}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube video player"
            className="absolute left-0 top-0 h-full w-full"
          ></iframe>
        </div>
      ) : (
        <div>
          <Image
            src="images/onboarding-placeholder.webp"
            alt="placeholder"
            layout="responsive"
            width={100}
            height={100}
          />
        </div>
      )}

      <OnboardingModalFooter handleModal={handleModal} />
    </div>
  );
}

function BetaExplained(props) {
  let { content, handleModal } = props;
  return (
    <div className="my-16">
      <div>
        {!isEmpty(content) &&
          content.map((element, index) => {
            return (
              <div key={index} className="mb-20">
                {element.heading && (
                  <div>
                    <strong>{element.heading}</strong>
                  </div>
                )}
                {element.text && <div>{element.text}</div>}
              </div>
            );
          })}
      </div>
      <div className="border-t border-dark">
        <OnboardingModalFooter handleModal={handleModal} />
      </div>
    </div>
  );
}

function OnboardingModalFooter({ handleModal }) {
  return (
    <div className="my-16 flex items-center justify-end">
      <div className="px-24">
        <Link
          href={`${config?.baseUrl}/disclosures`}
          className="underline"
          aria-label="Read the Disclaimer on disclosures"
        >
          Disclaimer
        </Link>
      </div>
      <div className="flex flex-col justify-between self-stretch">
        <button
          className="cta btn btn-primary text-center"
          onClick={() => handleModal()}
          aria-label="Get started"
        >
          Get Started
        </button>
      </div>
    </div>
  );
}

export default OnboardingModal;
