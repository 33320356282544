import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const getBaseUrl = () => {
  if (typeof window !== "undefined") {
    // Client-side code
    const hostname = window.location.host;
    if (hostname.includes("pre-prod")) {
      return "https://pre-prod.hedgewithetfs.com/";
    } else if (
      window.location.host.includes("hedgewithetfs") &&
      !window.location.host.includes("int") &&
      !window.location.host.includes("pre-prod")
    ) {
      return "https://www.hedgewithetfs.com/";
    } else {
      return "https://int.hedgewithetfs.com/"; // default
    }
  }
};

const config = {
  ...(publicRuntimeConfig || {}),
  errorComponentTitle: "This error has originated from a component",
  locales: [],
  baseUrl: getBaseUrl(),
};

export default config;
